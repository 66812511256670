import React from 'react';
import './Info.scss';
const Info = () => {
  return (
    <div>
      <section className="clean-block clean-info bg-dark text-white pb-lg-5">
        <div id="how-it-works"></div>
        <div className="container">
          <div className="block-heading text-center">
            <h2 className="text-primary">About Us</h2>
            {/* <p className="m-auto w-50 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam urna, dignissim nec auctor in, mattis vitae leo.</p> */}
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <iframe
                src="https://discord.com/widget?id=849965432910774272&theme=dark"
                width="100%"
                height="500"
                allowtransparency="true"
                frameborder="0"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              ></iframe>
            </div>
            <div className="col-md-6">
              <h3 className="my-4">Where Gamers & Organisers Meet</h3>
              <div className="getting-started-info">
                <blockquote class="blockquote">
                  <p>
                    E-Warz is an Online Pro tournament hosting platform which provides fun new
                    opportunities for both professional as well as casual gamers. <br />
                    It is a creative platform built for gamers to earn money while showing their pro
                    gaming skills.
                  </p>
                  <p>A place where true gamers Unite!.</p>
                </blockquote>
              </div>
              <a
                className="btn btn-outline-primary btn-lg"
                target="_blank"
                href="https://discord.com/invite/5a3HJeP4Bq"
                type="button"
              >
                Join Now
              </a>
            </div>
          </div>
          <div id="games"></div>
        </div>
      </section>
    </div>
  );
};

export default Info;
