import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import About from '../components/About/About';
import BlockHeading from '../components/BlockHeading/BlockHeading';
import Features from '../components/Features/Features';
import Hero from '../components/Hero/Hero';
import Info from '../components/Info/Info';
import Layout from '../components/layout/Layout';

const IndexPage = () => {
    
  return (
    <Layout>
      <Helmet title="E-Warz Tournaments | Pro Tournaments, Made Easy!" />
      {/* <span
        style={{
          position: 'fixed',
          bottom: '10px',
          left: '10px',
          zIndex: 12,
        }}
        className="text-warning"
      >
        Site is Under Construction...
      </span> */}
      <Hero />
      {/* <About /> */}
      <Info />
      <Features />
      <div id="tournaments"></div>
      <div className="bg-dark text-white">
        <div className="container">
          <BlockHeading
            title="Upcoming Tournaments"
            // description="Games available for tournaments"
          />
          <div class="block-content pt-3 pb-5">
            <div class="clean-blog-post px-2">
              {/* <div class="row">
                <div class="col-lg-5">
                  <StaticImage
                    src="../images/valorant-game-bg.png"
                    className="rounded img-fluid float-right"
                    alt="Valorant"
                  />
                  <img src="" />
                </div>
                <div class="col-lg-7">
                  <h3>Valorant Tournament</h3>
                  <div class="lead">
                    by&nbsp;<a href="#">E-Warz</a>&nbsp;@&nbsp;<span class="lead">10-08-21</span>
                  </div>
                  <p>this will be our first tournament...</p>
                  <a class="btn btn-outline-primary btn-sm" type="button">
                    Read More
                  </a>
                </div>
              </div> */}
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h4>(No Upcoming tournaments for now.)</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-dark-alt text-white pb-5">
        <BlockHeading title="Games" description="Games available for tournaments" />
        <div className="container">
          <div class="card-deck">
            <div class="card bg-dark text-white">
              <StaticImage
                className="card-img"
                src="../images/valorant-poster.png"
                // className="float-right"
                alt="Valorant"
              />
              {/* <img src="" class="card-img" alt="..." /> */}
              <div class="card-img-overlay">
                <h5 class="card-title my-5"></h5>
                <p class="card-text">
                  a 5v5 character-based tactical FPS where precise gunplay meets unique agent
                  abilities.
                </p>
                {/* <p class="card-text">Last updated 3 mins ago</p> */}
              </div>
            </div>
            <div class="card bg-dark text-white">
              <StaticImage
                className="card-img"
                src="../images/dota2-poster.png"
                // className="float-right"
                alt="Valorant"
              />
              <div class="card-img-overlay">
                {/* <h5 class="card-title">Card title</h5> */}
                <p class="card-text text-dark">
                  a multiplayer online battle arena (MOBA) that boasts over 100 playable characters,
                  130 unique items, and countless hours of fast-paced gameplay.
                </p>
                {/* <p class="card-text">Last updated 3 mins ago</p> */}
              </div>
            </div>
            <div class="card bg-dark text-white">
              <StaticImage
                className="card-img"
                src="../images/minecraft-poster.png"
                // className="float-right"
                alt="Valorant"
              />
              <div class="card-img-overlay">
                {/* <h5 class="card-title">Card title</h5> */}
                <p class="card-text text-dark">
                  Minecraft is a game about placing blocks and going on adventures. Minecraft has
                  two game modes and they each offer their own challenges.
                </p>
                {/* <p class="card-text">Last updated 3 mins ago</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
