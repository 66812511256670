import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import './Hero.css';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Hero = () => {
  return (
    <div className="container-fluid clean-block clean-hero">
      <div className="container">
        <div class="row">
          <div class="col-sm hero-left">
            <div className="text text-white">
              <div style={{ display: 'flex' }}>
                <h1 className="text-primary text-bold">
                  <strong>E-Warz</strong>
                </h1>
                <h5
                  className="my-3"
                  style={{
                    alignSelf: 'end',
                    marginLeft: '10px',
                    marginBottom: 0,
                    fontStyle: 'italic',
                  }}
                >
                  : Pro Tournaments, Made Easy!
                </h5>
              </div>

              {/* <h2 className="my-3">Pro Tournaments, Made Easy.</h2> */}
              <ul className="mt-1">
                <li>Join large scale tournaments</li>
                <li>Efficiently manage your esports team</li>
                <li>Organise your tournaments with no hassle.</li>
                <li>and much more..</li>
              </ul>
              {/* <h5 className="my-3">Join your favourite tournaments, Easily Collab with other skilled gamers, Manage or Organise Your tournaments and E-Sports orgs. everything at your fingertips.</h5> */}
              <button
                className="btn btn-outline-light btn-lg mt-2"
                onClick={() => scrollTo('#how-it-works')}
                type="button"
              >
                Learn More
              </button>
            </div>
          </div>
          <div class="col-sm hero-left text-white">
            <StaticImage
              src="../../images/app-ss1.png"
              width={250}
              className="float-right"
              //   quality={100}
              //   formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Gatsby astronaut"
              //   style={{ marginBottom: `1.45rem` }}
            />
          </div>
        </div>
        <div id="about"></div>
      </div>
    </div>
  );
};

export default Hero;
