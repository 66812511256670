import React from 'react';
import './Features.css';
const Features = () => {
  return (
    <div>
      <section className="clean-block features bg-dark-alt text-white pb-lg-5">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-primary">How it Works</h2>
            <p>
              E-Warz is a gaming platform which focuses on organising different E-Sports events,
              E-Warz will provide all the organisers with a very modern platform, to host events as
              well as help them to execute it efficiently.
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4 feature-box">
              <i className="far fa-star icon"></i>
              <h4>Join/Make a Team</h4>
              <p>
                You and your friends can make there own team, or they can join other teams as
                well.
              </p>
            </div>
            <div className="col-md-4 feature-box">
              <i className="far fa-edit icon"></i>
              <h4>Register for Tournament</h4>
              <p>
                Enroll in numerous array of free and paid tournaments to get you started in your esports carrier.
              </p>
            </div>
            <div className="col-md-4 feature-box">
              <i className="fas fa-mobile icon"></i>
              <h4>Play & Win</h4>
              <p>
               Compete and excel your skills with other fellow gamers. You can even win prizes on certain tournaments.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
