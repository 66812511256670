import React from 'react'
// import './BlockHeading.css'

const BlockHeading = ({title, description}) => {
    // console.log(props);
    return (
        <div className="py-5">
            <div class="block-heading text-center">
                <h2 class="text-primary">{title}</h2>
                <p style={{display: !description && "none", marginBottom:0}}>{description}</p>
            </div>
        </div>
    );
};

export default BlockHeading;